import React from "react"
import { graphql, Link } from 'gatsby'
import Img from "gatsby-image"
import styled from 'styled-components'
import Slider from "react-slick"

import Layout from "../components/layout-v2"
import SEO from "../components/seo"
import TeamMemberBuilder from "../components/team-member-builder"
import ContactUs from "../components/contact-us"

import LongLeftArrow from '../images/white-arrow-left.svg'
import LongRightArrow from '../images/white-arrow-right.svg'

const TeamMemberTemplate = ({ data: { teamMember, allTeamMembers } }) =>  {

    const layouts = teamMember.TeamMemberPageBuilder.tmPageBuilder;
    const teamMembers = allTeamMembers.edges

    const settings = {
      arrows: true,
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
            }
        }
      ]
    };

    return (
        <Layout>
          <SEO 
          title={teamMember.SEOmeta.metaTitle} 
          description={teamMember.SEOmeta.metaDescription}
          keywords={teamMember.SEOmeta.metaKeywords}
          ogTitle={teamMember.SEOmeta.ogTitle} 
          ogDescription={teamMember.SEOmeta.ogDescription}
          ogImage={teamMember.SEOmeta.ogImage.localFile.childImageSharp.fluid}
          twitterTitle={teamMember.SEOmeta.twitterTitle} 
          twitterDescription={teamMember.SEOmeta.twitterDescription}
          twitterImage={teamMember.SEOmeta.twitterImage.localFile.childImageSharp.fluid}
          />
            <article style={{padding: '0 20px', overflow: 'hidden'}}>
                <TitleSection>
                    <h1>{teamMember.title}</h1>
                    <p>{teamMember.TeamMemberJobTitle.tmJobTitle}</p>
                </TitleSection>
                <div>
                    {   
                        layouts.map((layout, index) => {
                            return <TeamMemberBuilder key={index} layoutData={layout} />
                        })
                    }
                </div>

                <SliderContainer>
                    <h3>Meet the rest of the team</h3>
                  <Slider {...settings}>
                    {teamMembers.map(post => (
                    <OurWorkSlide>
                        <Link to={`/about/meet-the-team/${post.node.slug}`} >
                            <div class="team-image">
                                <Img fluid={post.node.featuredImage.node.localFile.childImageSharp.fluid} alt={post.node.title} />
                            </div>
                            <div class="team-hover">
                                <h2 dangerouslySetInnerHTML={{ __html: post.node.title }} itemProp="headline"/>
                                <h3>{post.node.TeamMemberJobTitle.tmJobTitle}</h3>
                            </div>
                        </Link>
                    </OurWorkSlide> 
                    ))}
                  </Slider>
                </SliderContainer>

                <ContactUs />
            </article>
        </Layout>
    )

}

const TitleSection = styled.header`
    max-width: 1320px;
    width: 100%;
    padding: 80px 70px;
    margin: 0 auto; 
    margin-top: 20px;
    margin-bottom: 60px;
    border: 10px solid #db4140;
    h1 {
        font-size: 100px;
        margin-top: 0;
        margin-bottom: 0;
    }
    p {
        font-family: "Work Sans";
        font-size: 30px;
        font-weight: 400;
        color: #2b2520;
        margin-bottom: 0;
    }
    @media(max-width:1000px) {
      max-width: 700px;
      padding: 45px;
      h1 {
        font-size: 60px;
      }
      p {
        font-size: 24px;
      }
  }
  @media(max-width: 767px) {
      max-width: 500px;
      padding: 20px;
      margin-bottom: 20px;
      border: 5px solid #db4140;
      h1 {
        font-size: 44px;
      }
      p {
        font-size: 18px;
        line-height: 1.4;
      }
  }
`

const SliderContainer = styled.div`
    max-width: 1370px;
    width: 100%;
    margin: 70px auto;
    margin-bottom: 110px;
    padding: 0;
    h3 {
        text-align: center;
        position: relative;
        margin: 0;
        margin-bottom: 35px;
        font-size: 44px;
        font-family: "Nobel",sans-serif;
    }
    .slick-prev {
        width: 50px;
        height: 50px;
        left: 0px;
        background-color: #db4140;
        background-image: url(${LongLeftArrow});
        background-size: 50%;
        background-position: center;
        background-repeat: no-repeat;
        color: transparent;
        z-index: 15;
        transition-duration: .3s;
        &:before {
            display: none;
        }
        &:hover {
          background-color: #2b2520;
          background-image: url(${LongLeftArrow});
        }
    }
      .slick-next {
          width: 50px;
          height: 50px;
          right: 0px;
          background-color: #db4140;
          background-image: url(${LongRightArrow});
          background-size: 50%;
          background-position: center;
          background-repeat: no-repeat;
          color: transparent;
          z-index: 15;
          transition-duration: .3s;
          &:before {
              display: none;
          }
          &:hover {
          background-color: #2b2520;
          background-image: url(${LongRightArrow});
          }
      }
      .slick-dots {
          bottom: -40px;
          z-index: 15;
          li {
              margin: 0 10px;
              button {
                  width: 30px;
                  height: 30px;
                  background-color: transparent;
                  border: 4px solid #aaa;
                  border-radius: 50%;
                  margin: 0 auto;
                  transition-duration: .3s;
                  &:before {
                      display: none;
                  }
              }
              &.slick-active {
                  button {
                    background-color: #db4140;
                    border-color: #db4140;
                  }
              }
              &:hover {
                  button {
                    background-color: #db4140;
                    border-color: #db4140;
                  }
              }
          }
      }
    @media(max-width:1000px) {
      max-width: 700px;
    }
    @media(max-width:767px) {
      max-width: 500px;
      margin: 50px auto;
      margin-bottom: 70px;
    }
`

const OurWorkSlide = styled.div`
    padding: 25px;
    height: 400px;
    position: relative;
    div.team-image {
        position: absolute;
        left: 25px;
        top: 25px;
        height: calc(100% - 50px);
        width: calc(100% - 50px);
        .gatsby-image-wrapper {
            height: 100%;
        }
    }
    div.team-hover {
        position: absolute;
        left: 40px;
        width: calc(100% - 80px);
        background-color: #fff;
        z-index: 10;
        padding: 15px;
        transition-duration: .5s;
        opacity: 0;
        bottom: 0;
    }
    h2 {
        font-family: "Nobel",sans-serif;
        font-size: 30px;
        line-height: 42px;
        font-weight: 700;
        color: #2b2520;
        margin-top: 0px;
        margin-bottom: 0px;
        transition-duration: .3s;
    }
    h3 {
        font-family: "Work Sans";
        font-size: 18px;
        line-height: 20px;
        font-weight: 400;
        color: #777c7c;
        margin-top: 0;
        margin-bottom: 0px;
        text-align: left;
    }
    &:hover {
        div.team-hover {
            opacity: 1;
            bottom: 40px;
        }
    }
    @media(max-width: 767px) {
        div.team-hover {
            opacity: 1;
            bottom: 40px;
        }
        h2 {
            font-size: 24px;
            line-height: 1.2;
        }
    }
`

export default TeamMemberTemplate

export const pageQuery = graphql`
  query TeamMemberById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting all teammembers minus the current member
    allTeamMembers: allWpWlTeamMember(sort: {fields: date, order: DESC}, filter: {id: {ne: $id}}) {
      edges {
        node {
          title
          slug
          featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 770, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          TeamMemberJobTitle {
              tmJobTitle
          }
        }
      }
    }
    # selecting the current team member by id
    teamMember: wpWlTeamMember(id: { eq: $id }) {
      id
      title
      date(formatString: "MMMM DD, YYYY")
      SEOmeta {
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        ogImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      TeamMemberJobTitle {
        tmJobTitle
      }
      TeamMemberPageBuilder {
        tmPageBuilder {
          __typename
          ... on WpWLTeamMember_Teammemberpagebuilder_TmPageBuilder_TmFullWidthImage {
            fieldGroupName
            tmFullImage {
              title
              localFile {
                childImageSharp {
                    fluid(maxWidth: 1320, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
              }
            }
          }
          ... on WpWLTeamMember_Teammemberpagebuilder_TmPageBuilder_TmTwoThirdsOneThirdImage {
            fieldGroupName
            tmTwoThirdsContent
            tmOneThirdImage {
              title
              localFile {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
              }
            }
          }
          ... on WpWLTeamMember_Teammemberpagebuilder_TmPageBuilder_TmQuoteSection {
            fieldGroupName
            tmQuoteContent
            tmQuoteMeta
            tmQuoteSectionTitle
          }
        }
      }
    }
  }
`