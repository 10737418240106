// src/layouts/TmFullWidthImage/FullImageBlock.js

import React from "react"
import styled from 'styled-components'
import Img from "gatsby-image"

const TmFullWidthImage = ({ tmFullImage }) => {

  return (
    <BlockContent>
      <div
      data-sal="slide-up"
      data-sal-duration="1000"
      data-sal-delay="300"
      data-sal-easing="ease"
      >
        <Img fluid={tmFullImage.localFile.childImageSharp.fluid} alt={tmFullImage.title} />
      </div>
    </BlockContent>
  )
}

const BlockContent = styled.section`
    max-width: 1360px;
    width: 100%;
    margin: 60px auto;
    padding: 0px 20px;
    @media(max-width:1000px) {
      max-width: 700px;
      flex-wrap: wrap;
      padding: 0px;
    }
    @media(max-width:767px) {
      max-width: 500px;
      padding: 0;
      margin-top: 20px;
      margin-bottom: 20px;
    }
`

export default TmFullWidthImage