// src/components/team-member-builder.js

import React from "react"
import TmFullWidthImage from "../layouts/TmFullWidthImage"
import TmTwoThirdsOneThirdImage from "../layouts/TmTwoThirdsOneThirdImage"
import TmQuoteSection from "../layouts/TmQuoteSection"

const TeamMemberBuilder = ({ layoutData }) => {

  const layoutType = layoutData.fieldGroupName

  /**
   * Default component
   */
  const Default = () => <div>In AllLayouts the mapping of this component is missing: {layoutType}</div>

  /**
   * Mapping the fieldGroupName(s) to our components
   */
  const layouts = {
    WLTeamMember_Teammemberpagebuilder_TmPageBuilder_TmFullWidthImage: TmFullWidthImage,
    WLTeamMember_Teammemberpagebuilder_TmPageBuilder_TmTwoThirdsOneThirdImage: TmTwoThirdsOneThirdImage,
    WLTeamMember_Teammemberpagebuilder_TmPageBuilder_TmQuoteSection: TmQuoteSection,
    page_default: Default
  }

  /**
   * If layout type is not existing in our mapping, it shows our Default instead.
   */
  const ComponentTag = layouts[layoutType] ? layouts[layoutType] : layouts['page_default']

  return (
    <ComponentTag {...layoutData} />
  )
}

export default TeamMemberBuilder