// src/layouts/TmQuoteSection/TmQuoteSection.js

import React from "react"
import styled from 'styled-components'

import TestimonialBg from '../../images/testimonial-bg.png'
import StartQuote from '../../images/start-quote.png'
import EndQuote from '../../images/end-quote.png'

const TmQuoteSection = ({ tmQuoteSectionTitle, tmQuoteContent, tmQuoteMeta }) => {

  return (
    <BlockContent 
      data-sal="slide-up"
      data-sal-duration="1000"
      data-sal-delay="300"
      data-sal-easing="ease"
    >
      <h4>{tmQuoteSectionTitle}</h4>
      <Background>
        <img src={TestimonialBg} class="testimonial-bg" alt="Gray dotted background" height="650" width="1320" />
      </Background>
      <TestimonialMain>
        <img src={StartQuote} class="start-quote" alt="Start Quote" height="235" width="108" />
        <div>
          <p>{tmQuoteContent}</p>
          <p>{tmQuoteMeta}</p>
        </div>
        <img src={EndQuote} class="end-quote" alt="End Quote" height="235" width="107" />
      </TestimonialMain>
    </BlockContent>
  )
}

const BlockContent = styled.section`
  position: relative;
  max-width: 1360px;
  width: 100%;
  margin: 70px auto;
  padding: 0 20px;
  padding-top: 80px;
  padding-bottom: 220px;
  h4 {
    text-align: center;
    z-index: 10;
    position: relative;
    margin: 0;
    padding-bottom: 80px;
    font-size: 36px;
    font-family: "Nobel",sans-serif;
  }
  @media(max-width:1000px) {
    max-width: 700px;
  }
  @media(max-width: 767px) {
    h4 {
      font-size: 28px;
    }
    max-width: 500px;
    margin: 50px auto;
    padding-top: 40px;
    padding-bottom: 80px;
    overflow: hidden;
  }
`

const Background= styled.div`
  position: absolute;
  top: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  @media(max-width: 767px) {
    left: 0px;
    width: 100%;
    img {
      height: 200%;
    }
  }
`

const TestimonialMain = styled.div`
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 70px 80px;
  padding-bottom: 50px;
  p {
    &:first-child {
      font-family: "Nobel",sans-serif;
      font-size: 38px;
      line-height: 1.2;
      font-weight: 700;
      color: #1f1e1e;
      margin-bottom: 0;
      text-align: center;
      position: relative;
      z-index: 10;
    }
    &:last-child {
      font-family: "Work Sans";
      font-size: 24px;
      line-height: 1.2;
      font-weight: 700;
      color: #1f1e1e;
      margin-bottom: 0;
      margin-top: 35px;
      text-align: center;
      position: relative;
      z-index: 10;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }
  img.start-quote {
    position: absolute;
    top: -180px;
    left: -10px;
  }
  img.end-quote {
    position: absolute;
    bottom: -270px;
    right: 0px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 20px;
    left: 20px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1;
  }
  &:after {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    border: 10px solid #db4140;
    z-index: 1;
  }
  @media(max-width:1000px) {
    max-width: 570px;
    p {
      font-size: 24px;
      line-height: 1.4;
    }
  }
  @media(max-width: 767px) {
    padding: 25px 50px;
    p {
      &:first-child {
        font-size: 24px;
      }
      &:last-child {
        font-size: 16px;
      }
    }
    img.start-quote {
      max-width: 50px;
      height: auto;
      top: -80px;
      left: calc(50% - 25px);
    }
    img.end-quote {
      max-width: 50px;
      height: auto;
      bottom: -115px;
      right: calc(50% - 25px);
    }
    &:before {
      top: 10px;
      left: 20px;
      width: calc(100% - 30px);
      height: calc(100% - 5px);
    }
    &:after {
      top: 0px;
      left: 10px;
      width: calc(100% - 25px);
      border: 5px solid #db4140;
    }
  }
`

export default TmQuoteSection